<template>
  <b-overlay
    :show="$apollo.loading || mutationLoading"
    rounded="sm"
    spinner-variant="primary"
  >
    <!-- search input -->
    <div class="custom-search d-flex justify-content-start">
      <b-form-group
        class="form-inline"
        label="Search"
        label-size="sm"
      >
        <b-form-input
          v-model="searchTerm"
          class="d-inline-block mr-1"
          placeholder="Search knowledge base"
          type="text"
        />
        <b-button
          v-b-modal.knowledgeBaseModal
          variant="primary"
        >
          <feather-icon icon="PlusIcon" />
          Add Knowledge Base
        </b-button>
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table
      :columns="columns"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
      :rows="rows"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <span v-if="props.column.field === 'file'">
          <Promised
            v-if="props.row.is_internal === true"
            :promise="getLink(props.row.file)"
          >
            <template v-slot:pending>
              <b-button
                class="mr-50"
                disabled
                size="sm"
                variant="outline-primary"
              ><feather-icon icon="ClockIcon" /></b-button>
            </template>
            <template v-slot="data">
              <b-button
                :href="data"
                class="mr-50"
                size="sm"
                target="_blank"
                variant="outline-primary"
              >View</b-button>
            </template>
            <template v-slot:rejected>
              <span v-b-tooltip="`File not found`">
                <b-button
                  class="mr-50"
                  disabled
                  size="sm"
                  variant="outline-danger"
                ><feather-icon icon="AlertTriangleIcon" /></b-button>
              </span>
            </template>
          </Promised>
          <b-button
            v-else
            :href="prependHttps(props.row.file)"
            class="mr-50"
            size="sm"
            target="_blank"
            variant="outline-primary"
          >View</b-button>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3','5','10']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :per-page="pageLength"
              :total-rows="props.total"
              :value="1"
              class="mt-1 mb-0"
              first-number
              last-number
              next-class="next-item"
              prev-class="prev-item"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <b-modal
      id="knowledgeBaseModal"
      ok-only
      ok-title="Upload"
      size="lg"
      title="Add Knowledge Base"
      @ok="addFile"
    >
      <b-row>
        <b-col cols="12">
          <b-form-group
            label="Title"
            label-size="sm"
          >
            <b-form-input
              v-model="title"
              placeholder="Title"
              required
              type="text"
            />
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group
            label="File"
            label-size="sm"
          >
            <b-form-input
              v-model="file"
              placeholder="File Link"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Description"
            label-size="sm"
          >
            <b-form-input
              v-model="description"
              placeholder="File Description"
            />
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group
            label="Operation"
            label-size="sm"
          >
            <treeselect
              v-model="selectedOperation"
              :normalizer="normalizer"
              :options="operations"
              placeholder="Select Phase, Subphase or Activities"
            />
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group
            label="Recommendation Level"
            label-size="sm"
          >
            <v-select
              v-model="recommendationLevel"
              :options="['Priority', 'Interesting', 'Additional reading', 'Good read']"
              placeholder="Recommendation Level"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-modal>
  </b-overlay>
</template>
<script>

import {
  BButton, BCol, BFormGroup, BFormInput, BFormSelect, BOverlay, BPagination, BRow,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import gql from 'graphql-tag'
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import vSelect from 'vue-select'
import { Promised } from 'vue-promised'
// import axiosIns from '@/libs/axios'

export default {
  components: {
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BButton,
    BOverlay,
    VueGoodTable,
    BPagination,
    BFormSelect,
    vSelect,
    Treeselect,
    Promised,
    // BFormFile,
  },
  data() {
    return {
      mutationLoading: false,
      searchTerm: '',
      selectedRow: 0,
      pageLength: 5,
      filter: {
        status: 'Current',
      },
      columns: [
        {
          label: 'Title',
          field: 'document_name',
        },
        {
          label: 'Description',
          field: 'description',
        },
        {
          label: 'Recommendation',
          field: 'recommendation',
        },
        {
          label: 'Operation',
          field(row) {
            return row.programs_operationstable.title
          },
        },
        {
          label: 'File',
          field: 'file',
          sortable: false,
        },
      ],
      rows: [],
      file: null,
      recommendationLevel: null,
      title: null,
      description: '',
      selectedOperation: null,
    }
  },
  methods: {
    prependHttps(link) {
      if (link.indexOf('http://') === 0 || link.indexOf('https://') === 0) {
        return link
      }
      return `https://${link}`
    },
    getLink(key) {
      return fetch(`https://4rf1lach53.execute-api.ap-south-1.amazonaws.com/dev/programs/files/?file_key=${key}`, {
        method: 'GET',
      }).then(response => response.json())
        .then(data => data.url)
    },
    normalizer(node) {
      return {
        id: node.id,
        label: node.title,
        children: node.programs_operationstables,
      }
    },
    addFile() {
      // const formData = new FormData()
      // formData.append('file', this.file)
      // axiosIns.post('/upload_s3',
      //   formData, {
      //     headers: {
      //       'Content-Type': 'multipart/form-data',
      //     },
      //   })
      //   .then(res => console.log(res))
      this.mutationLoading = true
      this.$apollo.mutate(
        {
          mutation: gql`mutation MyMutation {
                      insert_programs_knowledgeresourcetable_one(object: {document_name: "${this.title}",recommendation: "${this.recommendationLevel}", description: "${this.description}", file: "${this.file}", operations_id: ${this.selectedOperation}}) {
                        id
                      }
                    }`,
          update: () => {
            this.mutationLoading = false
            this.$apollo.queries.rows.refetch()
          },
        },
      )
      this.title = null
      this.file = null
      this.description = null
      this.selectedOperation = null
      this.recommendationLevel = null
      this.selectedOperation = null
    },
  },
  apollo: {
    rows: {
      query() {
        return gql`
        {
          programs_knowledgeresourcetable(where: {programs_operationstable: {program_id: {_eq:  ${this.$route.params.id}}}}) {
            id
            description
            document_name
            file
            is_internal
            recommendation
            programs_operationstable {
              title
            }
          }
        }`
      },
      update: data => data.programs_knowledgeresourcetable,
    },
    operations: {
      query() {
        return gql`{
            programs_operationstable(where: {program_id: {_eq: ${this.$route.params.id}}, operations_type: {_eq: "Phase"}}) {
                  id
                  title
                  programs_operationstables(where: {operations_type: {_eq: "SubPhase"}}) {
                    id
                    title
                    programs_operationstables {
                      id
                      title
                    }
                  }
                }
              }`
      },
      update: data => data.programs_operationstable,
    },
  },
}
</script>
