<template>
  <b-overlay
    :show="$apollo.loading"
    spinner-variant="primary"
  >
    <!-- search input -->
    <div class="custom-search d-flex justify-content-start">
      <b-form-group
        class="form-inline"
        label="Search"
        label-size="sm"
      >
        <b-form-input
          v-model="searchTerm"
          class="d-inline-block mr-1"
          placeholder="Search reviews"
          type="text"
        />
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table
      :columns="columns"
      :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
      :rows="rows"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >

        <span v-if="props.column.field === 'action'">
          <b-button
            :to="`/mentor-portal/programs/milestone/${$route.params.id}/${$route.params.pid}/${$route.params.sid}/${props.row.id}`"
            class="mr-50"
            size="sm"
            variant="outline-primary"
          >
            <span>Open</span>
          </b-button>
        </span>

        <span v-else-if="props.column.field === 'stage_number'">
          <b-badge
            v-b-tooltip="'Stage ' + props.row.stage_number"
            variant="primary"
            v-text="props.row.stage_number"
          /> {{ props.row.title }}
        </span>
        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
              v-model="pageLength"
              :options="['3','5','10']"
              class="mx-1"
              @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :per-page="pageLength"
              :total-rows="props.total"
              :value="1"
              class="mt-1 mb-0"
              first-number
              last-number
              next-class="next-item"
              prev-class="prev-item"
              @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </b-overlay>
</template>

<script>
import gql from 'graphql-tag'
import {
  BBadge, BButton, BFormGroup, BFormInput, BFormSelect, BOverlay, BPagination,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
// import store from '@/store'

export default {
  components: {
    BBadge,
    BOverlay,
    BFormInput,
    BFormGroup,
    BButton,
    VueGoodTable,
    BPagination,
    BFormSelect,
  },
  data() {
    return {
      rows: [],
      searchTerm: '',
      selectedRow: 0,
      pageLength: 5,
      filter: {
        status: 'Current',
      },
      columns: [
        {
          label: 'Title',
          field: 'stage_number', // To enable sorting by Stage Number
        },
        {
          label: 'Action',
          field: 'action',
          width: '13rem',
          sortable: false,
        },
      ],
    }
  },
  apollo: {
    rows: {
      query() {
        return gql`
      {
        programs_progressstagetable(where: {startup_participant_id: {_eq: ${this.$route.params.sid} }}, order_by: {stage_number: asc}) {
            title
            stage_number
            id
            programs_progressrecordtables{
              recommendations
              status
            }
          }
      }`
      },
      update: data => data.programs_progressstagetable,

    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>
