<template>
  <section id="card-navigation">
    <b-row>
      <!-- pill tabs inside card -->
      <b-col md="12">
        <b-overlay
          :show="$apollo.loading"
          spinner-variant="primary"
        >
          <b-card>
            <b-card-header
              class="justify-content-start p-0 pb-1 mb-1 border-bottom"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-secondary"
                @click="$router.back()"
              >
                <feather-icon
                  class="mr-25"
                  icon="ChevronLeftIcon"
                />
              </b-button>
              <h3 class="mb-0 ml-2">
                {{ program.title }}
              </h3>
            </b-card-header>

            <b-tabs
              v-model="activeTab"
              active
              nav-class="mb-3"
              pills
              class="pb-2"
              @input="updateHash"
            >
              <b-tab
                active
                class="text-left"
                href="Activities"
                lazy
                title="Activities"
              >
                <Activities />
              </b-tab>
              <b-tab

                class="text-left"
                href="Review"
                lazy
                title="Review"
              >
                <Review />
              </b-tab>
              <b-tab
                class="text-left"
                href="Milestone"
                lazy
                title="Milestone"
              >
                <Milestone />
              </b-tab>
              <b-tab
                href="KnowledgeBase"
                lazy
                title="Knowledge Base"
              >
                <KnowledgeBase />
              </b-tab>
              <b-tab
                href="EMeet"
                lazy
                title="E Meet"
              >
                <VideoConferencing />
              </b-tab>
            </b-tabs>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BCol,
  BRow,
  BTab,
  BTabs,
  BOverlay, BCard, BCardHeader, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Milestone from '@/views/mentor-portal/programs/enter/Milestone.vue'
import gql from 'graphql-tag'
import Activities from './Activities.vue'
import Review from './Review.vue'
import KnowledgeBase from './KnowledgeBase.vue'
import VideoConferencing from './VideoConferencing.vue'

export default {
  components: {
    Activities,
    Review,
    KnowledgeBase,
    VideoConferencing,
    Milestone,
    BRow,
    BCol,
    BTab,
    BTabs,
    BCard,
    BCardHeader,
    BOverlay,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      activeTab: 0,
      tabs: ['#Activities',
        '#Review',
        '#Milestone',
        '#KnowledgeBase',
        '#EMeet',
        '#ChangeStartup'],
      program: {},
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.activeTab = this.tabs.findIndex(tab => tab === this.$route.hash)
    })
  },
  methods: {
    updateHash(e) {
      this.$router.replace(this.$route.path + this.tabs[e])
    },
  },
  apollo: {
    program: {
      query() {
        return gql`
        {
        programs_basicinfo_by_pk(id: "${this.$route.params.id}"){
          title
        }
      }`
      },
      update: data => data.programs_basicinfo_by_pk,
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
